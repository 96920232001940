// stuff I need to put in a .env file ================================================================================================================================================================================================================================================
import axios from 'axios';
import './videoplayer.css';
import { v4 as uuidv4 } from 'uuid';

const API_URL = process.env.REACT_APP_API_URL;


/* GENEREAL NOTES
- sometimes videoplayer freezes but timebar continues which unsyncs image with time (can't seem to reproduce bug)
  - scrubbing or pausing fixes it

- external commands (e.g. seeking with mac touchbar) fucks up canvas handling
  - dont think this is urgent, nobody will do this frfr



*/


// private functions ================================================================================================================================================================================================================================================
const leadingZeroFormatter = new Intl.NumberFormat(undefined, {
  minimumIntegerDigits: 2,
})
/**
 * Formats the duration in hours, minutes, and seconds.
 *
 * @param {number} time - The time in seconds.
 * @returns {string} The formatted duration.
 */

export function formatDuration(time) {
  const seconds = Math.floor(time % 60)
  const minutes = Math.floor(time / 60) % 60
  const hours = Math.floor(time / 3600)
  if (hours === 0) {
    return `${minutes}:${leadingZeroFormatter.format(seconds)}`
  } else {
    return `${hours}:${leadingZeroFormatter.format(
      minutes
    )}:${leadingZeroFormatter.format(seconds)}`
  }
}
/**
 * Calculates the margins needed scale the drawing properly
 *
 * @param {HTMLVideoElement} videoElement - The video element.
 * @returns {Object} The margins needed to center the video.
 * @returns {number} marginX - The margin on the X-axis.
 * @returns {number} marginY - The margin on the Y-axis.
 */

export function calculateMargins(videoElement, canvas) {
  // Get displayed dimensions of the video
  const rect = videoElement.getBoundingClientRect();
  const videoWidth = rect.width;
  const videoHeight = rect.height;

  const canvasWidth = canvas.width;
  const canvasHeight = canvas.height;

  if (videoWidth === 0 || videoHeight === 0 || canvasWidth === 0 || canvasHeight === 0) {
    return { marginX: 0, marginY: 0 };
  }

  const videoAspectRatio = videoWidth / videoHeight;
  const canvasAspectRatio = canvasWidth / canvasHeight;

  let newVideoWidth, newVideoHeight;

  if (canvasAspectRatio > videoAspectRatio) {
    // Canvas is wider than the video; fit by height
    newVideoHeight = canvasHeight;
    newVideoWidth = canvasHeight * videoAspectRatio;
  } else {
    // Canvas is taller than the video; fit by width
    newVideoWidth = canvasWidth;
    newVideoHeight = canvasWidth / videoAspectRatio;
  }

  // Calculate the margins to center the video in the canvas
  const marginX = (canvasWidth - newVideoWidth) / 2;
  const marginY = (canvasHeight - newVideoHeight) / 2;

  return { marginX, marginY };
}



/**
 * Easing function for smooth animations.
 * @param {number} t - Current time.
 * @param {number} b - Start value.
 * @param {number} c - Change in value.
 * @param {number} d - Duration.
 * @returns {number} - Calculated value at current time.
 */
function easeInOutQuad(t, b, c, d) {
  t /= d / 2;
  if (t < 1) return (c / 2) * t * t + b;
  t--;
  return (-c / 2) * (t * (t - 2) - 1) + b;
}

/**
 * Smoothly scrolls a container to a target position.
 *
 * @param {HTMLElement} container - The scrollable container element.
 * @param {HTMLElement} target - The target element to scroll into view.
 * @param {number} duration - Duration of the scroll animation in milliseconds.
 */
export function smoothScrollTo(container, target, duration) {
  const start = container.scrollTop;
  const targetPosition = target.offsetTop - 30;
  const change = targetPosition - start;
  const startTime = performance.now();

  function animateScroll(currentTime) {
    const elapsed = currentTime - startTime;
    const progress = Math.min(elapsed / duration, 1); // Ensure progress doesn't exceed 1
    const easedProgress = easeInOutQuad(elapsed, start, change, duration);
    container.scrollTop = easedProgress;

    if (elapsed < duration) {
      requestAnimationFrame(animateScroll);
    }
  }

  requestAnimationFrame(animateScroll);
}




/**
 * Calculates the position percentage of a given save time relative to the video's total duration.
 *
 * @param {number} saveTime - The save time in seconds.
 * @param {React.RefObject<HTMLVideoElement>} videoRef - Reference to the video element.
 * @returns {number} The position percentage.
 */
export function calculatePositionPercentage(saveTime, videoRef) {
  if (!videoRef.current) return;

  const video = videoRef.current;
  const totalDuration = video ? video.duration : 100; 

  const positionPercentage = totalDuration ? (saveTime / totalDuration) * 100 : 0;
    
  return positionPercentage;
}

/**
 * Removes a timeline indicator and its corresponding annotation card based on time.
 *
 * @param {string|number} time - The time identifier for the annotation (e.g., "2.2").
 * @param {Document} document - The document object.
 */
export function removeAnnotation(time, document) {
  // Ensure time is a string with one decimal place
  const timeId = parseFloat(time).toFixed(1);

  // Remove the timeline indicator
  const indicator = document.getElementById(`indicator-${timeId}`);
  if (indicator) {
    indicator.parentNode.removeChild(indicator);
  } else {
  }

  // Remove the annotation card
  const annotationCard = document.getElementById(`annotation-card-${timeId}`);
  if (annotationCard) {
    annotationCard.parentNode.removeChild(annotationCard);
  } else {
  }
}



/**
 * Adds a timeline indicator to the document.
 *
 * @param {string|number} canvasId - The time identifier for the annotation (e.g., "2.2").
 * @param {number} positionPercentage - The position percentage for the indicator.
 * @param {Document} document - The document object.
 */
export function addTimelineIndicator(time, positionPercentage, document, title = '') {
  const timeline = document.querySelector('.timeline');
  if (timeline) {
    // Format the time to one decimal place to use as an ID
    const timeId = parseFloat(time).toFixed(1);
    const indicatorId = `indicator-${timeId}`;
    
    // Avoid duplicates
    if (document.getElementById(indicatorId)) {
      return;
    }
    
    const indicator = document.createElement('button');
    indicator.className = 'timeline-indicator';
    indicator.id = indicatorId;
    indicator.style.left = `${positionPercentage}%`;
    
    // Attach the annotation title as a data attribute
    indicator.dataset.title = title;
    
    timeline.appendChild(indicator);
  } else {
    console.error('Timeline not found');
  }
}
/**
 * Adds an annotation card to the annotation list.
 *
 * @param {Object} annotation - The annotation object containing time, title, and commentary.
 * @param {Document} document - The document object.
 */
export function addAnnotationCard(annotation, document) {
  const annotationList = document.querySelector('.annotation-list');
  
  if (annotationList) {
    // Use time-based identifier for the card ID
    const timeId = parseFloat(annotation.time).toFixed(1); // Ensuring one decimal place
    const cardId = `annotation-card-${timeId}`;

    // Check if the card already exists
    if (document.getElementById(cardId)) {
      return; // Skip adding duplicate card
    }

    const card = document.createElement('div');
    card.className = 'annotation-card';
    card.id = cardId;

    // Ensure that title, description, and time are correctly referenced
    const title = annotation.title || 'Untitled';  // Fallback if title is missing
    const description = annotation.description || ' ';  // Fallback if description is missing
    const time = annotation.time;  // Raw time for comparison and sorting

    // Structure of the annotation card with a delete button
    card.innerHTML = `
      <button class="annotation-delete-button" aria-label="Delete Annotation">&times;</button> <!-- X symbol for delete -->
      <div class="annotation-time">${formatDuration(time)}</div>
      <div class="annotation-title">${title}</div>
      <div class="annotation-commentary">${description}</div>
    `;

    // Insert the card in the correct order based on time
    const existingCards = annotationList.children;
    
    let inserted = false;
    for (let i = 0; i < existingCards.length; i++) {
      // Compare the raw time (in seconds) for sorting, not the formatted time
      const existingTime = parseFloat(existingCards[i].getAttribute('data-time'));
      
      if (parseFloat(time) < existingTime) {
        annotationList.insertBefore(card, existingCards[i]); // Insert before the current card
        inserted = true;
        break;
      }
    }

    // If no card has a later time, append this card at the end
    if (!inserted) {
      annotationList.appendChild(card);
    }

    // Store the raw time as a data attribute for easier comparison
    card.setAttribute('data-time', time);

    // Note: We will attach event listeners to the delete buttons elsewhere
  } else {
    console.error('Annotation list container not found');
  }
}







// exported functions ================================================================================================================================================================================================================================================

/**
 * Handles the pause event for the video.
 *
 * @param {React.RefObject<HTMLVideoElement>} videoRef - Reference to the video element.
 * @param {HTMLElement} videoContainer - The container element for the video.
 * @param {Function} setLastPausedTime - Function to set the last paused time.
 */
export function handlePause(videoRef, videoContainer, setLastPausedTime) {
  if (!videoRef.current) return;
  videoContainer.classList.add("paused");
  const currTime = videoRef.current.currentTime.toFixed(1);
  setLastPausedTime(currTime);
}
/**
 * Handles the play event for the video.
 *
 * @param {React.RefObject<HTMLVideoElement>} videoRef - Reference to the video element.
 * @param {HTMLElement} videoContainer - The container element for the video.
 * @param {number} lastPausedTime - The last paused time of the video.
 */
export function handlePlay(videoRef, videoContainer, lastPausedTime, canvasManager) {
  if (!videoRef.current) return;

  videoContainer.classList.remove("paused");
  videoRef.current.currentTime = parseFloat(lastPausedTime);
  canvasManager.removeRestartIcon();
  
}
/**
 * Handles the end event for the video.
 *
 * @param {React.RefObject<HTMLVideoElement>} videoRef - Reference to the video element.
 * @param {number} lastPausedTime - The last paused time of the video.
 */
export function handleVideoEnd(videoRef,lastPausedTime, setPlaybackState) {
  if (!videoRef.current) return;

  videoRef.current.currentTime = lastPausedTime; // either this or set it to 0
  //setLastPausedTime(0);
  videoRef.current.pause();
  setPlaybackState("paused");

}
/**
 * Handles the loaded data event for the video.
 *
 * @param {React.RefObject<HTMLVideoElement>} videoRef - Reference to the video element.
 * @param {HTMLElement} totalTimeElem - The element displaying the total time of the video.
 */
export function handleLoadedData(videoRef, totalTimeElem) {
  if (!videoRef.current) return;

  if (!videoRef) return;
  totalTimeElem.textContent = formatDuration(videoRef.current.duration);
}
/**
 * Handles the timeline update event.
 * modified .x to .clientX to pass tests, not sure if works
 *
 * @param {MouseEvent} e - The mouse event.
 * @param {HTMLElement} timelineContainer - The container element for the timeline.
 * @param {boolean} isScrubbing - Indicates if scrubbing is in progress.
 */
export function handleTimelineUpdate(e, timelineContainer, isScrubbing) {
  if (!timelineContainer) {
    console.error("timelineContainer is undefined");
    return;
  }
  const rect = timelineContainer.getBoundingClientRect();
  const percent = Math.min(Math.max(0, e.clientX - rect.left), rect.width) / rect.width;  
  if (isScrubbing) {
    e.preventDefault();
    timelineContainer.style.setProperty("--progress-position", percent);
  }
}


/**
 * Handles the time update event for the video.
 *
 * @param {React.RefObject<HTMLVideoElement>} videoRef - Reference to the video element.
 * @param {HTMLElement} currentTimeElem - The element displaying the current time of the video.
 * @param {HTMLElement} timelineContainer - The container element for the timeline.
 */
export function handleTimeUpdate(videoRef, currentTimeElem, timelineContainer) {
  if (!videoRef.current) return;

  // if current time greater than total time, jsut set equal to total time
  if (videoRef.current.currentTime > videoRef.current.duration) {
    videoRef.current.currentTime = videoRef.current.duration;
  }

  currentTimeElem.textContent = formatDuration(videoRef.current.currentTime);
  const percent = videoRef.current.currentTime / videoRef.current.duration;
  timelineContainer.style.setProperty("--progress-position", percent);
}
/**
 * Toggles the draw mode.
 *
 * @param {string} mode - The draw mode to toggle.
 * @param {React.RefObject<HTMLVideoElement>} videoRef - Reference to the video element.
 * @param {Function} setDrawMode - Function to set the draw mode.
 */
export function toggleDrawMode(mode, videoRef, setDrawMode, setPlaybackState) {
  if (!videoRef.current) return;

  setDrawMode(prevMode => {
    const newMode = (prevMode === mode ? '' : mode);
    if (videoRef.current && newMode !== '' && prevMode !== newMode) {
      videoRef.current.pause();
      setPlaybackState("paused");
    }
    return newMode;
  });
}
/**
 * Toggles the visibility of the color palette.
 *
 * @param {Document} document - The document object.
 */
export function toggleColorPalette(document) {
  const colorPaletteContainer = document.querySelector(".color-palette-container");
  const thicknessSlider = document.querySelector(".thickness-slider");
  if (colorPaletteContainer.style.display === "none") {
    colorPaletteContainer.style.display = "flex";
    thicknessSlider.style.display = "block";
    setTimeout(() => {
      colorPaletteContainer.style.opacity = "0.8";
      thicknessSlider.style.opacity = "0.8";
    }, 10);
  } else {
    colorPaletteContainer.style.opacity = "0";
    thicknessSlider.style.opacity = "0";
    setTimeout(() => {
      colorPaletteContainer.style.display = "none";
      thicknessSlider.style.display = "none";
    }, 150);
  }
}

/**
 * Adds a flash animation to a button.
 *
 * @param {HTMLElement} button - The button element to animate.
 */
export function clickAnimation(button) {
  button.classList.add("flash");
  setTimeout(() => {
    button.classList.remove("flash");
  }, 150);
}
/**
 * Changes the playback speed of the video.
 *
 * @param {React.RefObject<HTMLVideoElement>} videoRef - Reference to the video element.
 * @param {HTMLElement} speedBtn - The button element displaying the playback speed.
 */
export function changePlaybackSpeed(videoRef, speedBtn) {
  if (!videoRef.current) return;

  let newPlaybackRate = videoRef.current.playbackRate + 0.25
  if (newPlaybackRate > 2) newPlaybackRate = 0.25
  videoRef.current.playbackRate = newPlaybackRate
  speedBtn.textContent = `${newPlaybackRate}x`
}




// classes ================================================================================================================================================================================================================================================

/**
 * @typedef {Object} VideoPlaybackHandlerArgs
 * @property {React.RefObject<HTMLVideoElement>} videoRef - The reference to the video element.
 * @property {string} videoLink - The link to the video source.
 * @property {HTMLElement} videoContainer - The container element for the video.
 * @property {React.RefObject<HTMLCanvasElement>} coldCanvasRef - The reference to the cold canvas element.
 * @property {Function} setCanvasEdited - Function to set the canvas edited state.
 * @property {Function} setLastPausedTime - Function to set the last paused time.
 * @property {number} lastPausedTime - The last paused time of the video.
 * @property {Object} canvasManager - The canvas manager instance.
 * @property {Object.<string, string>} canvasStatus - The status of the canvas at different timestamps.
 * @property {Function} updateCanvasStatus - Function to update the canvas status.
 * @property {HTMLElement} currentTimeElem - The element displaying the current time.
 * @property {HTMLElement} timelineContainer - The container element for the timeline.
 * @property {React.RefObject<boolean>} isScrubbingRef - Reference object indicating if scrubbing is in progress.
 * @property {Function} setLoading - Function to set the loading state.
 * @property {React.RefObject<HTMLCanvasElement>} hotCanvasRef - The reference to the hot canvas element.
 * @property {Document} document - The document object.
 * @property {Function} setDrawMode - Function to set the draw mode.
 * @property {Function} resetStatus - Function to reset the canvas status.
 */
/**
 * Handles the playback of the video
 *
 * @export
 * @class VideoPlaybackHandler
 */
export class VideoPlaybackHandler {
  /**
   * Creates an instance of VideoPlaybackHandler.
   * @memberof VideoPlaybackHandler
   */
  constructor() {
    this.lastScrubEvent = null;
    this.animationFrameId = null;
    // Bind methods to ensure 'this' refers to the class instance
    this.indicatorsWithListeners = new Set();
    this.annotationCardsWithListeners = new Set();
    //this.handleMouseEnter = this.handleMouseEnter.bind(this);
    //this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleIndicatorClick = this.handleIndicatorClick.bind(this);
    this.handleCardClick = this.handleCardClick.bind(this);
    this.deleteButtonsWithListeners = new Set();

    // Initialize the set for tracking indicators with listeners
    this.indicatorsWithListeners = new Set();
    this.args = {
      videoRef: null,
      videoLink: null,
      videoContainer: null,
      coldCanvasRef: null,
      setCanvasEdited: null,
      setLastPausedTime: null,
      lastPausedTime: null,
      canvasManager: null,
      canvasStatus: null,
      updateCanvasStatus: null,
      currentTimeElem: null,
      timelineContainer: null,
      isScrubbingRef: null,
      setLoading: null,
      hotCanvasRef: null,
      document: null,
      setDrawMode: null,
      resetStatus: null,
      token: null
    }
    this.handleMouseMove = this.handleMouseMove.bind(this);
    this.currentHoveredIndicator = null;
  }
  /**
   * Sets the arguments for the VideoPlaybackHandler
   *
   * @param {VideoPlaybackHandlerArgs} args
   * @memberof VideoPlaybackHandler
   */
  setArgs(args) {
    this.args = args;
    // Initialize preview element when args are set
    const { timelineContainer, videoRef } = args;
    if (timelineContainer && !timelineContainer.querySelector('.timeline-preview')) {
      const previewElement = document.createElement('div');
      previewElement.className = 'timeline-preview';
      timelineContainer.appendChild(previewElement);
    }

    // Add mousemove listener to timeline container
    if (timelineContainer) {
      timelineContainer.addEventListener('mousemove', this.handleMouseMove);
      timelineContainer.addEventListener('mouseleave', this.handleMouseLeave);
    }
  }

/**
 * Sets the canvas size and position to overlay the video content area accurately.
 * @returns {Promise} - Resolves when the canvas is set.
 */
setCanvasSize() {
  const { videoRef, hotCanvasRef, coldCanvasRef } = this.args;
  const video = videoRef.current;

  if (!video) return Promise.resolve();

  return new Promise((resolve) => {
    const checkSize = () => {
      if (video.videoWidth && video.videoHeight) {
        const { contentWidth, contentHeight, offsetX, offsetY } = this.calculateVideoContentArea(video);

        if (hotCanvasRef.current && coldCanvasRef.current) {
          [hotCanvasRef.current, coldCanvasRef.current].forEach((canvas) => {
            // Set canvas internal dimensions to match video's intrinsic dimensions
            canvas.width = video.videoWidth;
            canvas.height = video.videoHeight;

            // Position the canvas to overlay the video content area
            canvas.style.position = 'absolute';
            canvas.style.left = `${offsetX}px`;
            canvas.style.top = `${offsetY}px`;
            canvas.style.width = `${contentWidth}px`;
            canvas.style.height = `${contentHeight}px`;

            // Optional: Ensure canvas transforms align with video scaling
            canvas.style.transform = 'none'; // Reset any transformations
          });
        }
        resolve();
      } else {
        // Video dimensions not yet updated, check again
        requestAnimationFrame(checkSize);
      }
    };
    checkSize();
  });
}


// Function to calculate the video content area
/**
 * Calculates the content area of the video excluding black bars.
 * @param {HTMLVideoElement} video - The video element.
 * @returns {Object} - Contains contentWidth, contentHeight, offsetX, offsetY.
 */
 calculateVideoContentArea(video) {
  const videoRect = video.getBoundingClientRect();
  const displayedVideoWidth = videoRect.width;
  const displayedVideoHeight = videoRect.height;

  const videoAspectRatio = video.videoWidth / video.videoHeight;
  const displayedAspectRatio = displayedVideoWidth / displayedVideoHeight;

  let contentWidth, contentHeight, offsetX, offsetY;

  if (displayedAspectRatio > videoAspectRatio) {
    // Black bars on the left and right (pillarboxing)
    contentHeight = displayedVideoHeight;
    contentWidth = contentHeight * videoAspectRatio;
    offsetX = (displayedVideoWidth - contentWidth) / 2; // Horizontal margin
    offsetY = 0;
  } else {
    // Black bars on the top and bottom (letterboxing)
    contentWidth = displayedVideoWidth;
    contentHeight = contentWidth / videoAspectRatio;
    offsetX = 0;
    offsetY = (displayedVideoHeight - contentHeight) / 2; // Vertical margin
  }

  return {
    contentWidth,
    contentHeight,
    offsetX, // The left/right black bar margin
    offsetY, // The top/bottom black bar margin
  };
}





  /**
   * Starts the frame-based drawing using requestAnimationFrame
   * 
   * this implementation might be perfect
   * if theres a problem, make the requestAnimationFrames watch the timelineContainer rather than time
   *
   * @return {*} 
   * @memberof VideoPlaybackHandler
   */
drawTracingPlay() {
  if (this.animationFrameId) return;

  const draw = () => {
    if (!this.args.videoRef.current) return;

    if (!this.args.videoRef.current.paused) {
      this.drawTracing();
      this.animationFrameId = requestAnimationFrame(draw);
    } else {
      this.animationFrameId = null;
    }
  };

  this.animationFrameId = requestAnimationFrame(draw);
}


  /**
   * Pauses the frame-based drawing
   *
   * @memberof VideoPlaybackHandler
   */
  drawTracingPause() {

    if (!this.args.videoRef.current) return
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
      this.animationFrameId = null;
    }
  }  

  /**
   * Ensures requestAnimationFrame stays in sync with the video playback after switching tabs
   *
   * @param {*} isVisible
   * @memberof VideoPlaybackHandler
   */
  handleVisibilityChange(isVisible) {
    const video = this.args.videoRef;
    const setPlaybackState = this.args.setPlaybackState;

    if (!video.current) return;
    if (isVisible) {
      const { videoRef } = this.args;
      if (videoRef.current) {
        this.drawTracingPlay();
      }
    } else {
      if (!video.current.paused){
         video.current.pause();
         setPlaybackState("paused");
      }
      this.drawTracingPause();
    }
  }
  
  /**
   * Checks for saved canvas and draws it on the cold canvas
   *
   * @param {boolean} [override=false]
   * @return {*} 
   * @memberof VideoPlaybackHandler
   */
  async drawTracing(override = false) {
    const {
      videoId,
      token,
      isScrubbingRef,
      videoRef,
      videoLink,
      coldCanvasRef,
      setCanvasEdited,
      setLastPausedTime,
      canvasStatus,
      updateCanvasStatus,
      currentTimeElem,
      timelineContainer,
      setLoading,
      setPlaybackState,
      document // Ensure document is included in args
    } = this.args;

    if (!videoLink || !videoRef.current || !coldCanvasRef.current) {
      console.error('videoLink is missing');
      return;
    }

    const currTime = videoRef.current.currentTime.toFixed(1).toString();
    const playedStatus = override ? "U" : canvasStatus[currTime];





    if (playedStatus === "U" && !isScrubbingRef.current) {
      if (!videoRef.current.paused) {
        videoRef.current.pause();
        setPlaybackState("paused");
      }
      setLoading(true);
      videoRef.current.pause();


      try {
        // Fetch annotation metadata
        const metadataResponse = await axios.get(`${API_URL}/meta_annotation/video/${videoId}/annotation`, {
          params: { time: currTime },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const annotationMetadata = metadataResponse.data;
        if (!annotationMetadata || !annotationMetadata.annotationId) {
          console.error('No annotation metadata found for this time.');
          setLoading(false);
          return;
        }

        const annotationId = annotationMetadata.annotationId;

        // Fetch the annotation data using the found annotationId
        const annotationResponse = await axios.get(`${API_URL}/annotation/${annotationId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const annotationData = annotationResponse.data;
        const canvasData = annotationData?.annotationData;
        const originalCanvasWidth = annotationData?.canvasWidth;
        const originalCanvasHeight = annotationData?.canvasHeight;
        const originalVideoWidth = annotationData?.videoWidth;
        const originalVideoHeight = annotationData?.videoHeight;

        if (!canvasData || !originalCanvasWidth || !originalCanvasHeight || !originalVideoWidth || !originalVideoHeight) {
          console.error('Incomplete annotation data.');
          setLoading(false);
          return;
        }

        // Ensure current video and canvas sizes are set
        await this.setCanvasSize();

        const currentCanvas = coldCanvasRef.current;
        const currentVideo = videoRef.current;

        // Get current displayed dimensions of the video
        const currentRect = currentVideo.getBoundingClientRect();
        const currentVideoWidth = currentRect.width;
        const currentVideoHeight = currentRect.height;

        // Calculate scaling factors based on video dimensions
        const scaleX = currentVideoWidth / originalVideoWidth;
        const scaleY = currentVideoHeight / originalVideoHeight;

        // Use the minimum scale factor to maintain aspect ratio
        const scale = Math.min(scaleX, scaleY);

        // Calculate adjusted dimensions for the annotation image
        const adjustedWidth = originalCanvasWidth * scale;
        const adjustedHeight = originalCanvasHeight * scale;

        // Calculate margins to center the annotation
        const adjustedMarginX = (currentCanvas.width - adjustedWidth) / 2;
        const adjustedMarginY = (currentCanvas.height - adjustedHeight) / 2;

        videoRef.current.currentTime = currTime; // to load correct image before displaying canvas

        // Draw the image with scaling and margins
        await new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = () => {
            const ctx_cold = currentCanvas.getContext('2d');
            ctx_cold.clearRect(0, 0, currentCanvas.width, currentCanvas.height);
            ctx_cold.drawImage(img, 0, 0, currentCanvas.width, currentCanvas.height);
            resolve();
          };
          img.onerror = (err) => {
            console.error('Image loading error:', err);
            reject(err);
          };
          img.src = canvasData;
        });

        setLastPausedTime(currTime);
        handleTimeUpdate(videoRef, currentTimeElem, timelineContainer);
        setCanvasEdited(true);
        updateCanvasStatus(currTime, "P");
        setLoading(false);

      } catch (error) {
        setLoading(false);
        console.error('Error fetching canvas data:', error);
      } finally {
        // fixes the near-indicator scrub bug 
        videoRef.current.pause();

        const currTime2 = videoRef.current.currentTime.toFixed(1); // Ensure one decimal place

        // Escape the dot in currTime2 for use in querySelector
        const escapedCurrTime = currTime2.replace('.', '\\.');

        // Select the corresponding timeline indicator
        const selectedIndicator = document.querySelector(`#indicator-${escapedCurrTime}`);

        if (selectedIndicator) {
          selectedIndicator.classList.add('glowing'); // Add the glowing class to the indicator
        } else {
          console.error(`Indicator for time ${currTime2} not found`);
        }

        // Select the corresponding annotation card
        const selectedCard = document.querySelector(`#annotation-card-${escapedCurrTime}`);

        if (selectedCard) {
          selectedCard.classList.add('glowing'); // Add the glowing class to the card

          // Scroll the annotation card into view using custom smoothScrollTo
          const annotationList = document.querySelector('.annotation-list');
          if (annotationList) {
            smoothScrollTo(annotationList, selectedCard, 1000); // 1000ms for slower scrolling
          } else {
            console.error('Annotation list container not found for scrolling');
          }

        } else {
          console.error(`Annotation card for time ${currTime2} not found`);
        }

        await new Promise(resolve => setTimeout(resolve, 100)); // Adjust the delay if needed

      }
    }
  }

  
  
  
  

   getVideoContentArea(videoWidth, videoHeight, canvasWidth, canvasHeight) {
    const videoAspectRatio = videoWidth / videoHeight;
    const canvasAspectRatio = canvasWidth / canvasHeight;
  
    let contentWidth, contentHeight;
  
    if (canvasAspectRatio > videoAspectRatio) {
      // Canvas is wider than video aspect ratio
      contentHeight = canvasHeight;
      contentWidth = contentHeight * videoAspectRatio;
    } else {
      // Canvas is taller than video aspect ratio
      contentWidth = canvasWidth;
      contentHeight = contentWidth / videoAspectRatio;
    }
  
    const offsetX = (canvasWidth - contentWidth) / 2;
    const offsetY = (canvasHeight - contentHeight) / 2;
  
    return { contentWidth, contentHeight, offsetX, offsetY };
  }
  
  
  
  
  


  
  /**
   * Initializes the timeline indicators wrt the saved canvases data
   *
   * @return {*} 
   * @memberof VideoPlaybackHandler
   */
  async initializeTimelineIndicators() {
    const { videoId, token, videoRef, document, updateCanvasStatus, setPreloading } = this.args;
    const timeline = document.querySelector('.timeline');
    
  
    if (!timeline) {
      console.error('Timeline not found');
      return;
    }
  
    if (!videoRef.current) {
      console.error('videoRef is missing');
      return;
    }
  
    try {
  
      // Fetch annotation metadata for the video
      const response = await axios.get(`${API_URL}/meta_annotation/video/${videoId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
  
      const annotations = response.data;
  
      // Clear the timeline and annotation list before updating
      timeline.innerHTML = '';
      const annotationList = document.querySelector('.annotation-list');
      if (annotationList) {
        annotationList.innerHTML = '';
      }
  
      // Sort the annotations by time if necessary
      const sortedAnnotations = annotations.sort((a, b) => parseFloat(a.time) - parseFloat(b.time));
  
      // Loop through the sorted annotations and fetch detailed data
      for (let item of sortedAnnotations) {
        const storedTime = parseFloat(item.time).toFixed(1); // Ensure one decimal
        if (storedTime >= 0) {
          const positionPercentage = calculatePositionPercentage(parseFloat(storedTime), videoRef);


          // Fetch detailed annotation data using annotationId
          const annotationResponse = await axios.get(`${API_URL}/annotation/${item.annotationId}`, {
            headers: {
              Authorization: `Bearer ${token}`
            }
          });

          const annotation = annotationResponse.data;

          addTimelineIndicator(storedTime, positionPercentage, document, annotation.title);
          updateCanvasStatus(storedTime, "U");

          // Add the annotation card to the annotation list with formatted time
          addAnnotationCard({
            annotationId: annotation.annotationId,
            title: annotation.title,
            description: annotation.description,
            time: parseFloat(annotation.time).toFixed(1)
          }, document);
        }
        
      }
      setPreloading(false);
      return true;
  
    } catch (error) {
      console.error('Error fetching annotation metadata or details:', error);
      return false;
    }

    
  }
  
  
  
  
  
  
/**
 * Handles the full screen change event
 *
 * @memberof VideoPlaybackHandler
 */
handleFullScreenChange() {
  const { 
    videoContainer,
    document,
    videoRef,
    lastPausedTime, 
    canvasStatus, 
    canvasManager,
    setDrawMode,
    setAnnotationTitle,
    setAnnotationCommentary,
    currentTimeElem,
    timelineContainer,
    setLastPausedTime,
    setCanvasStatus,
    resetStatus
  } = this.args;

  // Return if no video
  if (!videoRef.current) return;

  // Toggle fullscreen CSS class
  videoContainer.classList.toggle(
    "full-screen",
    !!(document.fullscreenElement || document.webkitFullscreenElement)
  );

  const isPaused = videoRef.current.paused;
  // Using the currentTime on the actual <video> in case user has scrubbed:
  const currentTime = parseFloat(videoRef.current.currentTime.toFixed(1));
  const timeKey = currentTime.toFixed(1);
  const timeExists = canvasStatus[timeKey] === "P" || canvasStatus[timeKey] === "U";

  if (isPaused && timeExists) {
    // Rewind by 0.2 seconds but stay paused
    const newTime = Math.max(0, currentTime - 0.2);
    videoRef.current.currentTime = newTime;
    setLastPausedTime(newTime.toFixed(1).toString());

    // Reset statuses so user must explicitly re-load or re-draw
    resetStatus(canvasStatus, setCanvasStatus);

    // Update on-screen time display and timeline progress
    handleTimeUpdate(videoRef, currentTimeElem, timelineContainer);


    videoRef.current.play();

  } else {
    // Otherwise, clear any annotation/draw mode data
    setDrawMode("");
    if (setAnnotationTitle && setAnnotationCommentary) {
      setAnnotationTitle("");
      setAnnotationCommentary("");
    }
    canvasManager.clearCanvas();
  }
}



  /**
   * Toggles the scrubbing of the video
   *
   * @param {*} e
   * @return {*} 
   * @memberof VideoPlaybackHandler
   */
  toggleScrubbing(e, loading = false, skipPrompt = false) {
    const { drawMode, videoRef, setPendingAction, setHasUnsavedChanges, hasUnsavedChanges } = this.args;
  
    // If user not drawing => never prompt
    if (drawMode === "") {
      this.performScrubbing(e, loading);
      return;
    }
  
    // If user IS drawing & skipPrompt === false => show prompt
    /*if (!skipPrompt && hasUnsavedChanges) {
      videoRef.current?.pause();
      setPendingAction("scrubbing");
      this.lastScrubEvent = e;
      return;
    }*/
  
    // Otherwise skipPrompt === true => do real scrubbing
    this.performScrubbing(e, loading);
  }

  performScrubbing(e, loading = false) {
    const {
      canvasManager,
      setCanvasEdited,
      setDrawMode,
      setAnnotationTitle,
      setAnnotationCommentary,
      videoRef,
      setPlaybackState,
      resetStatus,
      videoContainer,
      timelineContainer,
      setLastPausedTime,
      isScrubbingRef
      // any other needed props
    } = this.args;
  
    if (loading || !videoRef.current) return;
  
    canvasManager.removeRestartIcon();
    canvasManager.clearCanvas();
    setCanvasEdited(false);
    setDrawMode("");
    setAnnotationTitle("");
    setAnnotationCommentary("");
  
    videoRef.current.pause();
    setPlaybackState("paused");
    resetStatus();
  
    const rect = timelineContainer.getBoundingClientRect();
    const percent = Math.min(Math.max(0, e.clientX - rect.x), rect.width) / rect.width;
    isScrubbingRef.current = (e.buttons & 1) === 1;
    videoContainer.classList.toggle("scrubbing", isScrubbingRef.current);
  
    if (isScrubbingRef.current) {
      this.handleMouseMove(e); // Update preview while scrubbing
    }
  
    const newTime = percent * videoRef.current.duration;
    videoRef.current.currentTime = newTime;
  
    // If near end, show restart icon
    if (Math.abs(newTime - videoRef.current.duration) < 0.1) {
      canvasManager.toggleRestartIcon();
    }
  
    // ... handleTimeUpdate, setLastPausedTime, etc...
    setLastPausedTime(videoRef.current.currentTime.toFixed(1).toString());
    handleTimelineUpdate(e, timelineContainer, isScrubbingRef.current);
  }

  

  setupIndicatorEventListeners() {
    const { document } = this.args;
  
    const setupEventListeners = () => {
      const indicators = document.querySelectorAll('.timeline-indicator');
      const annotationCards = document.querySelectorAll('.annotation-card');
  
      // Setup event listeners for indicators
      indicators.forEach((indicator) => {
        if (!this.indicatorsWithListeners.has(indicator)) {
          //indicator.addEventListener('mouseenter', this.handleMouseEnter);
          //indicator.addEventListener('mouseleave', this.handleMouseLeave);
          indicator.addEventListener('click', this.handleIndicatorClick);
          
          // Hover event listeners for tooltip
          /*indicator.addEventListener('mouseenter', this.handleIndicatorHoverEnter);
          indicator.addEventListener('mouseleave', this.handleIndicatorHoverLeave);
          */
          this.indicatorsWithListeners.add(indicator);
        }
      });
  
      // Setup event listeners for annotation cards
      annotationCards.forEach((card) => {
        if (!this.annotationCardsWithListeners.has(card)) {
          //card.addEventListener('mouseenter', this.handleMouseEnter);
          //card.addEventListener('mouseleave', this.handleMouseLeave);
          card.addEventListener('click', this.handleCardClick);
          this.annotationCardsWithListeners.add(card);
        }
  
        // Setup event listeners for delete buttons within the cards
        const deleteButton = card.querySelector('.annotation-delete-button');
        if (deleteButton && !this.deleteButtonsWithListeners.has(deleteButton)) {
          deleteButton.addEventListener('click', this.handleDeleteButtonClick);
          this.deleteButtonsWithListeners.add(deleteButton);
        }
      });
    };
  
    // Initially set up event listeners on existing indicators and cards
    setupEventListeners();
  
    // Observe changes to attach listeners to new indicators
    this.indicatorObserver = new MutationObserver(() => {
      setupEventListeners();
    });
  
    // Start observing the timeline container for added children
    const timeline = document.querySelector('.timeline');
    if (timeline) {
      this.indicatorObserver.observe(timeline, { childList: true, subtree: true });
    }
  
    // Observe changes to attach listeners to new annotation cards
    this.annotationObserver = new MutationObserver(() => {
      setupEventListeners();
    });
  
    // Start observing the annotation list for added children
    const annotationList = document.querySelector('.annotation-list');
    if (annotationList) {
      this.annotationObserver.observe(annotationList, { childList: true, subtree: true });
    }
  
    // Initialize the sets if not already initialized
    if (!this.annotationCardsWithListeners) {
      this.annotationCardsWithListeners = new Set();
    }
    if (!this.deleteButtonsWithListeners) {
      this.deleteButtonsWithListeners = new Set();
    }
  }
  
  

  cleanupIndicatorEventListeners() {
    if (this.indicatorObserver) {
      this.indicatorObserver.disconnect();
      this.indicatorObserver = null;
    }
  
    // Remove event listeners from indicators
    this.indicatorsWithListeners.forEach((indicator) => {
      //indicator.removeEventListener('mouseenter', this.handleMouseEnter);
      //indicator.removeEventListener('mouseleave', this.handleMouseLeave);
      indicator.removeEventListener('click', this.handleIndicatorClick);
      // Remove hover event listeners
      /*indicator.removeEventListener('mouseenter', this.handleIndicatorHoverEnter);
      indicator.removeEventListener('mouseleave', this.handleIndicatorHoverLeave);
      */
    });
  
    // Clear the indicators set
    this.indicatorsWithListeners.clear();
  
    // Remove event listeners from annotation cards
    if (this.annotationCardsWithListeners) {
      this.annotationCardsWithListeners.forEach((card) => {
        //card.removeEventListener('mouseenter', this.handleMouseEnter);
        //card.removeEventListener('mouseleave', this.handleMouseLeave);
        card.removeEventListener('click', this.handleCardClick);
      });
  
      // Clear the annotation cards set
      this.annotationCardsWithListeners.clear();
    }
  
    // Remove event listeners from delete buttons
    if (this.deleteButtonsWithListeners) {
      this.deleteButtonsWithListeners.forEach((deleteButton) => {
        deleteButton.removeEventListener('click', this.handleDeleteButtonClick);
      });
  
      // Clear the delete buttons set
      this.deleteButtonsWithListeners.clear();
    }
  }
  

  handleDeleteButtonClick = (e) => {
    e.stopPropagation(); 
    e.preventDefault(); 
    e.currentTarget.blur();
  
    const deleteButton = e.currentTarget;
    const cardElem = deleteButton.closest('.annotation-card');
    const time = parseFloat(cardElem.getAttribute('data-time'));
    
    // Call toggleDeleteCanvas with the remoteTime parameter
    this.args.canvasManager.toggleDeleteCanvas(time);
  };
  

/*
// Method to handle hover enter event
handleIndicatorHoverEnter = (e) => {
  const indicatorElem = e.currentTarget;
  const timeId = indicatorElem.id.split('-')[1]; // Assuming id="indicator-420.8"
  const timeInSeconds = parseFloat(timeId);
  const formattedTime = formatDuration(timeInSeconds);

  // Create tooltip element
  const tooltip = document.createElement('div');
  tooltip.className = 'indicator-tooltip';
  tooltip.innerText = formattedTime;

  // Position the tooltip above the indicator
  tooltip.style.position = 'absolute';
  tooltip.style.bottom = '100%';
  tooltip.style.left = '50%';
  tooltip.style.transform = 'translateX(-50%) translateY(-5px)';
  tooltip.style.pointerEvents = 'none'; // Ensure it doesn't interfere with hover
  tooltip.style.zIndex = '10';

  // Attach tooltip to the indicator
  indicatorElem.appendChild(tooltip);

  // Store reference to the tooltip for later removal
  indicatorElem._tooltip = tooltip;
};

// Method to handle hover leave event
handleIndicatorHoverLeave = (e) => {
  const indicatorElem = e.currentTarget;
  const tooltip = indicatorElem._tooltip;

  if (tooltip) {
    indicatorElem.removeChild(tooltip);
    delete indicatorElem._tooltip;
  }
};
*/

// Ensure 'this' refers to the class instance in handlers
/*handleMouseEnter(e) {
  e.target.classList.add('hovered');
}

handleMouseLeave(e) {
  e.target.classList.remove('hovered');
}
*/

// Add this method to handle card clicks
handleCardClick = (e) => {
  const targetTime = parseFloat(e.currentTarget.id.split('-')[2]); // Assuming id="annotation-card-2.2"
  // Link to the existing indicatorClicked function
  this.indicatorClicked(targetTime);

  // Apply glow to the clicked card and corresponding indicator
  this.applyGlow(targetTime);
};


handleIndicatorClick = (e) => {
  const targetTime = parseFloat(e.currentTarget.id.split('-')[1]); // Assuming id="indicator-2.2"

  // Link to the existing indicatorClicked function
  this.indicatorClicked(targetTime);

  // Apply glow to the clicked indicator and corresponding card
  this.applyGlow(targetTime);
};

// Add this helper method to manage glow effects
applyGlow(targetTime) {
  // Escape the targetTime in case it contains special characters
  const safeTargetTime = CSS.escape(targetTime);

  // Remove existing glow from all indicators and cards
  const allIndicators = this.args.document.querySelectorAll('.timeline-indicator');
  const allCards = this.args.document.querySelectorAll('.annotation-card');

  allIndicators.forEach(indicator => indicator.classList.remove('glowing'));
  allCards.forEach(card => card.classList.remove('glowing'));

  // Add glow to the selected indicator
  const selectedIndicator = this.args.document.querySelector(`#indicator-${safeTargetTime}`);
  if (selectedIndicator) {
    selectedIndicator.classList.add('glowing');
  }

  // Add glow to the corresponding annotation card
  const selectedCard = this.args.document.querySelector(`#annotation-card-${safeTargetTime}`);
  if (selectedCard) {
    selectedCard.classList.add('glowing');
  }
}


  
// Function to handle clicking on a timeline indicator
indicatorClicked(targetTime) {
  const {
    loading,
    videoRef,
    canvasManager,
    setCanvasEdited,
    setDrawMode,
    resetStatus,
    setLastPausedTime,
    canvasStatus,
    currentTimeElem,
    timelineContainer,
    setPlaybackState,
    setAnnotationCommentary,
    setAnnotationTitle
  } = this.args;

  if (loading) return;
  if (!videoRef.current) return;

  // Pause the video
  videoRef.current.pause();
  setPlaybackState("paused");

  // Remove any restart icons and clear the canvas
  canvasManager.removeRestartIcon();
  canvasManager.clearCanvas();

  // Reset states
  setCanvasEdited(false);
  setDrawMode("");
  setAnnotationTitle("");
  setAnnotationCommentary("");
  resetStatus();

  // Move the video to the specific time
  videoRef.current.currentTime = targetTime;

  // Update the last paused time
  setLastPausedTime(targetTime.toFixed(1).toString());

  // Check if there is a saved canvas for this time
  const currTime = targetTime.toFixed(1).toString();
  const timeExists = canvasStatus[currTime] === 'P' || canvasStatus[currTime] === 'U';

  if (timeExists) {
    // Draw the saved tracing if it exists
    this.drawTracing(true);
  }

  // Update the timeline and current time display
  handleTimeUpdate(videoRef, currentTimeElem, timelineContainer);


}
handleMouseMove(e) {
  const { timelineContainer, videoRef } = this.args;
  if (!timelineContainer || !videoRef.current) return;

  // Get (or create) the preview element
  let preview = timelineContainer.querySelector('.timeline-preview');
  if (!preview) {
    preview = document.createElement('div');
    preview.className = 'timeline-preview';
    timelineContainer.appendChild(preview);
  }

  // Ensure the time and title elements exist
  let timeSpan = preview.querySelector('.preview-time');
  let titleSpan = preview.querySelector('.preview-title');
  if (!timeSpan) {
    timeSpan = document.createElement('span');
    timeSpan.className = 'preview-time';
    preview.appendChild(timeSpan);
  }
  if (!titleSpan) {
    titleSpan = document.createElement('span');
    titleSpan.className = 'preview-title';
    preview.appendChild(titleSpan);
  }
  const rect = timelineContainer.getBoundingClientRect();
  // Use an offset on the Y coordinate for the hit test
  const hoveredIndicator = document.elementFromPoint(e.clientX, e.clientY + 10)
                              ?.closest('.timeline-indicator');
  if (hoveredIndicator) {
    const timeId = hoveredIndicator.id.split('-')[1]; // e.g. "2.2"
    const annotationTitle = hoveredIndicator.dataset.title || '';
    timeSpan.textContent = formatDuration(parseFloat(timeId));
    titleSpan.textContent = annotationTitle;

    titleSpan.style.display = 'inline';

    preview.classList.add('indicator-preview');
    preview.style.left = hoveredIndicator.style.left;
    preview.style.display = 'flex';
    preview.classList.add('visible');
    this.currentHoveredIndicator = hoveredIndicator;
  } else {
    const percent = Math.min(Math.max(0, e.clientX - rect.left), rect.width) / rect.width;
    const previewTime = percent * videoRef.current.duration;


    timelineContainer.style.setProperty('--preview-position', percent);
    titleSpan.textContent = '';
    titleSpan.style.display = 'none';

    timeSpan.textContent = formatDuration(previewTime);
    titleSpan.textContent = ''; // Hide title if not hovering an indicator
    preview.classList.remove('indicator-preview');
    preview.style.left = `${e.clientX - rect.left}px`;
    preview.style.display = 'flex';
    preview.classList.add('visible');
    this.currentHoveredIndicator = null;
  }
}
  // Add this method to handle mouse leave
  handleMouseLeave = () => {
    const { timelineContainer } = this.args;
    const preview = timelineContainer.querySelector('.timeline-preview');
    if (preview) {
      preview.style.display = 'none';
      preview.classList.remove('visible');
    }
    this.currentHoveredIndicator = null;
  }

  cleanup() {
    if (this.args && this.args.timelineContainer) {
      this.args.timelineContainer.removeEventListener('mousemove', this.handleMouseMove);
      this.args.timelineContainer.removeEventListener('mouseleave', this.handleMouseLeave);
    }
  }
}

/**
 * @typedef {Object} CanvasManagerArgs
 * @property {React.RefObject<HTMLCanvasElement>} coldCanvasRef - Reference to the cold canvas element.
 * @property {function(boolean):void} setCanvasEdited - Function to set the canvas edited status.
 * @property {function(string):void} setDrawMode - Function to set the drawing mode.
 * @property {boolean} loading - Loading state.
 * @property {number} lastPausedTime - The last paused time of the video.
 * @property {React.RefObject<HTMLVideoElement>} videoRef - Reference to the video element.
 * @property {string} videoLink - Link to the video.
 * @property {Document} document - The document object.
 * @property {string} canvasStatus - The current status of the canvas.
 * @property {boolean} canvasEdited - Indicates whether the canvas has been edited.
 * @property {function():void} resetStatus - Function to reset the status.
 * @property {function(string):void} updateCanvasStatus - Function to update the canvas status.
 * @property {function(string):void} deleteCanvasStatus - Function to delete the canvas status.
 * @property {HTMLElement} videoContainer - The container element for the video.
 */
/**
 *
 * Manages the canvas element of the video player
 * @export
 * @class CanvasManager
 */
export class CanvasManager{
  /**
   * Creates an instance of CanvasManager.
   * @memberof CanvasManager
   */
  constructor(){
    this.args = {
      coldCanvasRef: null,
      setCanvasEdited: null,
      setDrawMode: null,
      loading: null,
      lastPausedTime: null,
      videoRef: null,
      videoLink: null,
      document: null,
      canvasStatus: null,
      canvasEdited: null,
      resetStatus: null,
      updateCanvasStatus: null,
      deleteCanvasStatus: null,
      videoContainer: null,
      videoEditContainer: null,
      setLastPausedTime: null,
      currentTimeElem: null,
      timelineContainer: null,
      speedBtn: null,
      token: null,
      setPlaybackState: null,
      annotationTitle: null,
      annotationCommentary: null
    };
  }
  /**
   *
   * Sets the arguments for the CanvasManager
   * @param {CanvasManagerArgs} args
   * @memberof CanvasManager
   */
  setArgs(args){
    this.args = args;
  }
  /**
   *
   * Clears any drawings on the canvas
   * @memberof CanvasManager
   */
  clearCanvas() {
    const { coldCanvasRef, setCanvasEdited, setHasUnsavedChanges } = this.args;
    if (!coldCanvasRef.current) return;
    const ctx_cold = coldCanvasRef.current.getContext('2d');
    ctx_cold.clearRect(0, 0, coldCanvasRef.current.width, coldCanvasRef.current.height);
  
    setCanvasEdited(false);
    setHasUnsavedChanges(false);
  }
  /**
   *
   * Saves the canvas in the database and visually
   * 
   * 
   * @memberof CanvasManager
   */
  async saveCanvas() {
    const {
      videoId,
      token,
      loading,
      coldCanvasRef,
      lastPausedTime,
      videoRef,
      videoLink,
      canvasEdited,
      document,
      updateCanvasStatus,
      setDrawMode,
      canvasStatus,
      annotationTitle,
      annotationCommentary,
      setAnnotationTitle,
      setAnnotationCommentary,
      setHasUnsavedChanges
    } = this.args;
  
    if (loading || !videoRef.current || !coldCanvasRef.current) return;
  
    const coldCanvas = coldCanvasRef.current;
    const canvasData = coldCanvas.toDataURL();
    const currTime = lastPausedTime;
    const video = videoRef.current;
    const canvasWidth = video.videoWidth;   // Intrinsic canvas width
    const canvasHeight = video.videoHeight; // Intrinsic canvas height
    const rect = video.getBoundingClientRect();
    const videoWidth = rect.width;
    const videoHeight = rect.height;
  
    const timeValid =
      !isNaN(parseFloat(currTime)) &&
      parseFloat(currTime) > 0 &&
      parseFloat(currTime) < videoRef.current.duration;
  
    if (!videoLink) {
      console.error('videoLink is missing');
      return;
    }
  
    if (canvasStatus[currTime] != null) { // Annotation exists
      return; // Exit the function early
    }
    else if (canvasEdited && timeValid) {
      const positionPercentage = calculatePositionPercentage(parseFloat(currTime), videoRef);
      updateCanvasStatus(currTime, 'P');
      addTimelineIndicator(currTime, positionPercentage, document, annotationTitle);
  
      try {
        // Generate a new annotation ID (UUID) if needed for backend
        const annotationId = uuidv4();
        

        // Save the canvas data to the annotations database
        const annotationPayload = {
          title: annotationTitle,
          description: annotationCommentary,
          annotationId,
          createdDate: new Date().toISOString(),
          time: currTime,
          annotationData: canvasData,
          canvasWidth,
          canvasHeight,
          videoWidth,    // Save video width
          videoHeight,   // Save video height
        };
  
        // Save the annotation
        await axios.post(`${API_URL}/annotation`, annotationPayload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  

        // Save the metadata
        await axios.post(
          `${API_URL}/meta_annotation`,
          {
            videoId,       // The videoId related to this annotation
            annotationId,  // The generated annotation ID
            time: currTime, // The timestamp of the annotation
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );


  
        // Construct the new annotation object as expected by addAnnotationCard
        const newAnnotation = {
          annotationId,         // Unique ID (for backend purposes)
          title: annotationTitle, // Annotation title
          description: annotationCommentary, // Annotation commentary
          time: currTime, // Ensure time is a string with one decimal
        };
  
        // Add the new annotation card to the annotation list
        addAnnotationCard(newAnnotation, document);
  
        // Reset draw mode and form fields after saving
        setDrawMode("");
        setAnnotationTitle("");
        setAnnotationCommentary("");
        setHasUnsavedChanges(false);
      } catch (error) {
        console.error('Error saving canvas and metadata:', error);
      }
    }
  }
  
  
  
  /**
   * Deletes canvas and associated metadata from the database.
   *
   * @param {number} currTime - The time of the annotation to delete.
   */
  async deleteCanvas(currTime) {
    const { videoId, token, canvasStatus, setDrawMode, setCanvasEdited, 
      setAnnotationCommentary, setAnnotationTitle } = this.args;

    const timeCanvas = canvasStatus[currTime] === "P" || canvasStatus[currTime] === "U";

    if (timeCanvas) {
      // Update UI after successful deletion
      setDrawMode("");
      setAnnotationTitle("");
      setAnnotationCommentary("");
      setCanvasEdited(false);

      try {
        // Directly attempt to delete annotation and metadata from the server
        await axios.delete(`${API_URL}/annotation`, {
          headers: {
            Authorization: `Bearer ${token}`
          },
          params: {
            videoId: videoId,
            time: currTime.toString()  // Ensure time is passed as a string
          }
        });
      } catch (error) {
        if (error.response && error.response.status === 404) {
          // Suppress the error entirely (no log needed)
        } else {
          // Log other errors (for debugging purposes)
          console.error('Error deleting canvas and metadata:', error.response ? error.response.data : error.message);
        }
      }
    } else {
      console.error('No canvas to delete at this time.');
    }
  }

  
  
  
/**
 * Toggles the deletion of the canvas and associated annotation.
 *
 * @param {number} [remoteTime=-1] - The time of the annotation to delete. If -1, uses the current paused time.
 */
/*toggleDeleteCanvas(remoteTime = -1) {
  const { setPendingAction, setPendingParams, lastPausedTime, canvasStatus, hasUnsavedChanges } = this.args;
  const currTime = remoteTime > -1 ? remoteTime : lastPausedTime;
  const timeExists = canvasStatus[currTime] === "P" || canvasStatus[currTime] === "U";

  if (hasUnsavedChanges || timeExists) {
    setPendingParams({ remoteTime: currTime }); // Store remoteTime immediately
    setPendingAction("deleteAnnotation"); // 🔥 Store it as a **simple string**
  }
}*/

/**
 * Confirms the deletion of an annotation.
 * @param {number} [remoteTime=-1] - The time of the annotation to delete. If -1, uses the current paused time.
 */

// previously confirmedDeleteAnnotation
async toggleDeleteCanvas(remoteTime = -1) {
  const { lastPausedTime, canvasStatus, deleteCanvasStatus, videoRef, document } = this.args;
  if (!videoRef.current) return;

  const currTime = remoteTime > -1 ? remoteTime : lastPausedTime;
  const timeExists = canvasStatus[currTime] === "P" || canvasStatus[currTime] === "U";

  if (timeExists) {
    this.clearCanvas();
    removeAnnotation(currTime, document);
    await this.deleteCanvas(currTime);
    deleteCanvasStatus(currTime);
  } else {
    this.clearCanvas();
  }
}
  

  
  
  
  /**
   *
   * Toggles the fullscreen mode of the video
   * @memberof CanvasManager
   */
  toggleFullScreenMode() {
    const { videoRef, document, videoContainerRef, setPendingAction, drawMode, setHasUnsavedChanges, hasUnsavedChanges } = this.args;
    if (!videoRef.current) return;
    // If user is actively drawing, show a prompt
    /*if (hasUnsavedChanges) {
      // Are we entering or exiting?
      if (!document.fullscreenElement) {
        setPendingAction("enterFullscreen");
      } else {
        setPendingAction("exitFullscreen");
      }
      // Pause so video doesn’t keep playing behind the prompt
      videoRef.current.pause();
      return;
    }*/
  
    // If user NOT drawing, do the operation right away
    if (!document.fullscreenElement) {
      videoContainerRef.current.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  }

  
  
  /**
   *
   * Toggles the container containing editing tools
   * @memberof CanvasManager
     */
  toggleVideoEditContainer() {
    const { videoEditContainer } = this.args;
    if (videoEditContainer.style.display === "none") {
      videoEditContainer.style.display = "flex";
      setTimeout(() => {
        videoEditContainer.style.opacity = "0.8";
      }, 10);
    } else {
      videoEditContainer.style.opacity = "0";
      setTimeout(() => {
        videoEditContainer.style.display = "none";
      }, 150);
    }
  }


/**
 * Toggles the play/pause of the video, optionally skipping the "unsaved changes" prompt.
 * @memberof CanvasManager
 */
togglePlay({
  override = false,
  playbackState,
  setPlaybackState,
  playPauseInProgress,
  skipPrompt = false,
}) {
  const {
    videoRef,
    setDrawMode,
    setCanvasEdited,
    loading,
    setLastPausedTime,
    setAnnotationCommentary,
    setAnnotationTitle,
    setPendingAction,
    drawMode,
    setHasUnsavedChanges,
    hasUnsavedChanges,
  } = this.args;

  if (!videoRef.current || !playPauseInProgress) return;
  if (loading || playPauseInProgress.current) return;

  // 1) If we have NOT overridden prompt logic and user is still drawing => show prompt
  /*if (!skipPrompt && hasUnsavedChanges) {
    videoRef.current.pause();
    setPendingAction("play");   // So the prompt knows "we want to resume playing"
    return;
  }*/

  // 2) Otherwise proceed with the normal logic
  playPauseInProgress.current = true;

  // Clear any drawing states if you want to discard unsaved edits
  setDrawMode("");
  setAnnotationTitle("");
  setAnnotationCommentary("");
  this.clearCanvas();
  setCanvasEdited(false);

  // Check if video is at or near the end
  const currTime = parseFloat(videoRef.current.currentTime.toFixed(1));
  const duration = parseFloat(videoRef.current.duration.toFixed(1));

  // If at the end, reset to 0 and play
  if (currTime === duration) {
    this.removeRestartIcon();
    setLastPausedTime("0.0");
    videoRef.current.currentTime = 0;

    videoRef.current.play()
      .then(() => {
        setPlaybackState("playing");
      })
      .catch((error) => {
        console.error('Play error:', error);
        setPlaybackState('paused');
      })
      .finally(() => {
        playPauseInProgress.current = false;
      });
    return;
  }

  // Normal play/pause toggle
  if (videoRef.current.paused) {
    videoRef.current.play()
      .then(() => {
        setPlaybackState('playing');
      })
      .catch((error) => {
        console.error('Play error:', error);
        setPlaybackState('paused');
      })
      .finally(() => {
        playPauseInProgress.current = false;
      });

  } else {
    // The video is playing; user wants to pause
    if (videoRef.current.currentTime.toFixed(1) >= 0 || override) {
      videoRef.current.pause();
      setPlaybackState('paused');
      playPauseInProgress.current = false;
    } else {
      playPauseInProgress.current = false;
    }
  }
}


/**
 * Advances the video by 5 seconds, optionally skipping the "unsaved changes" prompt.
 * @memberof CanvasManager
 */
forwardTime({
  playbackState,
  setPlaybackState,
  playPauseInProgress,
  skipPrompt = false,
} = {}) {
  const {
    videoRef,
    drawMode,
    setPendingAction,
    loading,
    setLastPausedTime,
    canvasStatus,
    setCanvasStatus,
    currentTimeElem,
    timelineContainer,
    resetStatus,
    setDrawMode,
    setHasUnsavedChanges,
    hasUnsavedChanges
  } = this.args;

  if (loading || !videoRef.current) return;


  

  // 1) If user is drawing & we haven't skipped prompt => ask for confirmation
  /*if (!skipPrompt && hasUnsavedChanges) {
    videoRef.current.pause();
    setPendingAction("forwardTime");
    return;
  }*/

  // 2) The normal skip logic
  this.clearCanvas();

  const currentTime = videoRef.current.currentTime;
  const duration = videoRef.current.duration;
  const timeLeft = duration - currentTime;
  videoRef.current.currentTime = (timeLeft < 5) ? duration : currentTime + 5;

  // Then all your existing code:
  setLastPausedTime(videoRef.current.currentTime.toFixed(1).toString());
  resetStatus(canvasStatus, setCanvasStatus);
  handleTimeUpdate(videoRef, currentTimeElem, timelineContainer);

  // Possibly auto-play after skipping:
  if (currentTime + 5 < duration) {
    this.togglePlay({ override: true, playbackState, setPlaybackState, playPauseInProgress });
    if (!videoRef.current.paused) {
      this.togglePlay({ override: true, playbackState, setPlaybackState, playPauseInProgress });
    }
  } else {
    this.toggleRestartIcon();
  }
}




/**
 * Goes back in time by 5 seconds, optionally skipping the "unsaved changes" prompt.
 * @memberof CanvasManager
 */
backTime({
  playbackState,
  setPlaybackState,
  playPauseInProgress,
  skipPrompt = false
} = {}) {
  const {
    videoRef,
    setLastPausedTime,
    canvasStatus,
    setCanvasStatus,
    currentTimeElem,
    timelineContainer,
    resetStatus,
    loading,
    drawMode,
    setPendingAction,
    setHasUnsavedChanges,
    hasUnsavedChanges
  } = this.args;

  if (loading || !videoRef.current) return;

  // 1) If user is drawing and skipPrompt == false => show the confirm
  /*if (!skipPrompt && hasUnsavedChanges) {
    videoRef.current.pause();
    setPendingAction("backTime");
    return;
  }*/

  // 2) Otherwise, proceed with the normal back 5s logic
  this.clearCanvas();

  const currentTime = videoRef.current.currentTime;
  const newTime = currentTime - 5;
  videoRef.current.currentTime = newTime < 0 ? 0 : newTime;

  setLastPausedTime(videoRef.current.currentTime.toFixed(1).toString());
  resetStatus(canvasStatus, setCanvasStatus);
  handleTimeUpdate(videoRef, currentTimeElem, timelineContainer);

  // The existing logic toggling play
  this.togglePlay({
    override: true,
    playbackState,
    setPlaybackState,
    playPauseInProgress
  });

  if (!videoRef.current.paused) {
    this.togglePlay({
      override: true,
      playbackState,
      setPlaybackState,
      playPauseInProgress
    });
  }

  this.removeRestartIcon();
}
  /**
   *
   * Handles the key-bound video interactions
   * @param {*} e
   * @memberof CanvasManager
   */
  keyDown(e, playbackState, setPlaybackState, playPauseInProgress) {
    const { videoRef, speedBtn, loading, drawMode, pendingAction } = this.args;
  
    // 1) If user is focusing an input or textarea, let them type
    const activeEl = document.activeElement;
    const isTypingField = activeEl && (activeEl.tagName.toLowerCase() === 'input' 
                                    || activeEl.tagName.toLowerCase() === 'textarea');
    if (isTypingField) {
      // do nothing => let the keystroke pass to the form
      return;
    }
  
    // 2) If a custom overlay is up, block all keys
    if (document.querySelector(".custom-overlay")) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
  
    if (!videoRef.current) return;
    if (loading) return;
  
    e.preventDefault();
    e.stopPropagation();
  
    switch (e.key.toLowerCase()) {
      default:
        break;
      case "arrowright":
        this.forwardTime();
        break;
      case "arrowleft":
        this.backTime();
        break;
      case " ":
        this.togglePlay({ playbackState, setPlaybackState, playPauseInProgress });
        break;
      case "f":
        this.toggleFullScreenMode();
        break;
      case "backspace":
        this.toggleDeleteCanvas();
        break;
      case "s":
        this.saveCanvas();
        break;
      case "+":
      case "=":
        changePlaybackSpeed(videoRef, speedBtn);
        break;
      case "escape":
        e.preventDefault();
        e.stopPropagation();
        break;
    }
  }
  /**
   *
   * Removes the restart icon
   * @return {*} 
   * @memberof CanvasManager
   */
  removeRestartIcon(){
    const {restartIconRef} = this.args;
    if (!restartIconRef.current) return;
    restartIconRef.current.style.display = 'none';
  }
  /**
   *
   * Toggles the restart icon
   * @return {*} 
   * @memberof CanvasManager
   */
  toggleRestartIcon() {
    const {restartIconRef} = this.args;
    if (!restartIconRef.current) return;
    if (restartIconRef.current) {
      restartIconRef.current.style.display = 'flex';
    }
  };
}

/**
 * @typedef {Object} DrawingArgs
 * @property {HTMLCanvasElement} hotCanvas
 * @property {HTMLCanvasElement} coldCanvas
 * @property {CanvasRenderingContext2D | null} ctx_cold
 * @property {CanvasRenderingContext2D | null} ctx_hot
 * @property {number} startX
 * @property {number} startY
 * @property {string} color
 * @property {number} thickness
 * @property {string} drawMode
 */
/**
 *
 * Handles the drawing on the canvas
 * @export
 * @class Drawing
 */
export class Drawing {
  /**
   * Creates an instance of Drawing.
   * @memberof Drawing
   */
  constructor() {
    /** @type {DrawingArgs} */
    this.args = {
      hotCanvas: null,
      coldCanvas: null,
      ctx_cold: null,
      ctx_hot: null,
      startX: null,
      startY: null,
      color: null,
      thickness: null,
      drawMode: null,
      hasUnsavedChanges: null
    };
    this.drawFunctionMap = {
      pen: {
        start: this.startDrawingPen.bind(this),
        draw: this.drawPen.bind(this),
        stop: this.stopDrawingPen.bind(this),
      },
      circle: {
        start: this.startDrawingCircle.bind(this),
        draw: this.drawCircle.bind(this),
        stop: this.stopDrawingCircle.bind(this),
      },
      arrow: {
        start: this.startDrawingArrow.bind(this),
        draw: this.drawArrow.bind(this),
        stop: this.stopDrawingArrow.bind(this),
      },
    };
  }
  /**
   *
   * Sets the drawing styles
   * @memberof Drawing
   */
  setDrawStyle() {
    const { color, thickness, ctx_hot, ctx_cold } = this.args;
    ctx_cold.strokeStyle = color;
    ctx_hot.strokeStyle = color;
    ctx_cold.lineWidth = thickness;
    ctx_hot.lineWidth = thickness;
  }
  /**
   * Sets the paramaters of the drawing tool, updates cursor and drawing style
   * @param {DrawingArgs} args  - 
   */
  updateDrawConfig(args) {
    this.args = args;
    this.setDrawStyle();
    this.clearCursor();
    this.setCursor();
  }
  /**
   *
   * Initializes the drawing tool for pen drawing
   * @param {*} - event
   * @memberof Drawing
   */
  startDrawingPen(e) {
    const { hotCanvas,  ctx_hot, setHasUnsavedChanges } = this.args;
    setHasUnsavedChanges(true);
    const svgOffsetY = 5;
    const svgOffsetX = -24;
    const rect = hotCanvas.getBoundingClientRect();
    const scaleX = hotCanvas.width / rect.width;
    const scaleY = hotCanvas.height / rect.height;
    ctx_hot.beginPath();
    ctx_hot.moveTo((e.clientX + svgOffsetX - rect.left) * scaleX, (e.clientY + svgOffsetY - rect.top) * scaleY);
  }
  /**
   *
   * Draws the pen on the canvas
   * @param {*} e - event
   * @memberof Drawing
   */
  drawPen(e) {
    const { hotCanvas, ctx_hot } = this.args;
    const svgOffsetY = 5;
    const svgOffsetX = -24;
    const rect = hotCanvas.getBoundingClientRect();
    const scaleX = hotCanvas.width / rect.width;
    const scaleY = hotCanvas.height / rect.height;
    ctx_hot.lineTo((e.clientX + svgOffsetX - rect.left) * scaleX, (e.clientY + svgOffsetY - rect.top) * scaleY);
    ctx_hot.stroke();
  }
  /**
   *
   * Handles the stopping of the pen drawing
   * @memberof Drawing
   */
  stopDrawingPen() {
    const { hotCanvas, ctx_hot, ctx_cold } = this.args;
    ctx_hot.closePath();
    ctx_cold.drawImage(hotCanvas, 0, 0);
    ctx_hot.clearRect(0, 0, hotCanvas.width, hotCanvas.height);
  }
  /**
   *
   * Initializes the drawing tool for circle drawing
   * @param {*} e
   * @memberof Drawing
   */
  startDrawingCircle(e) {
    const { hotCanvas, startX, startY, setHasUnsavedChanges } = this.args;
    setHasUnsavedChanges(true);
    startX.current = e.clientX - hotCanvas.getBoundingClientRect().left;
    startY.current = e.clientY - hotCanvas.getBoundingClientRect().top;
    const rect = hotCanvas.getBoundingClientRect();
    const scaleX = hotCanvas.width / rect.width;
    const scaleY = hotCanvas.height / rect.height;
    startX.current = (e.clientX - rect.left) * scaleX ;
    startY.current = (e.clientY - rect.top) * scaleY;
  }
  /**
   *
   * Draws the circle on the canvas
   * @param {*} e
   * @memberof Drawing
   */
  drawCircle(e) {
    const { hotCanvas, startX, startY, ctx_hot } = this.args;
    const rect = hotCanvas.getBoundingClientRect();
    const scaleX = hotCanvas.width / rect.width;
    const scaleY = hotCanvas.height / rect.height;
    const currentX = (e.clientX - rect.left) * scaleX ;
    const currentY = (e.clientY - rect.top) * scaleY;

    const shift = -25;
    const shifted_r = Math.sqrt((currentX - startX.current) ** 2 + (currentY - startY.current) ** 2) +shift;
    const radius = shifted_r > 0 ? shifted_r : 0;

    ctx_hot.clearRect(0, 0, hotCanvas.width, hotCanvas.height);
    ctx_hot.beginPath();
    ctx_hot.arc(startX.current, startY.current, radius, 0, 2 * Math.PI);
    ctx_hot.stroke();
  }
  /**
   *
   * Handles the stopping of the drawing of the circle
   * @memberof Drawing
   */
  stopDrawingCircle() {
    const { hotCanvas, ctx_hot, ctx_cold } = this.args;
    ctx_cold.drawImage(hotCanvas, 0, 0);
    ctx_hot.clearRect(0, 0, hotCanvas.width, hotCanvas.height);
  }
  /**
   *
   * Handles the arrowhead wrt the direction of the arrow
   * @param {*} ctx
   * @param {*} fromX
   * @param {*} fromY
   * @param {*} toX
   * @param {*} toY
   * @memberof Drawing
   */
  drawArrowHead(ctx, fromX, fromY, toX, toY) {
    const headlen = 10;
    const angle = Math.atan2(toY - fromY, toX - fromX);
    ctx.moveTo(toX, toY);
    ctx.lineTo(toX - headlen * Math.cos(angle - Math.PI / 6), toY - headlen * Math.sin(angle - Math.PI / 6));
    ctx.moveTo(toX, toY);
    ctx.lineTo(toX - headlen * Math.cos(angle + Math.PI / 6), toY - headlen * Math.sin(angle + Math.PI / 6));
  }
  /**
   *
   * Initializes the drawing tool for arrow drawing
   * @param {*} e
   * @memberof Drawing
   */
  startDrawingArrow(e) {
    const { hotCanvas, startX, startY, setHasUnsavedChanges } = this.args;
    setHasUnsavedChanges(true);
    startX.current = e.clientX - hotCanvas.getBoundingClientRect().left;
    startY.current = e.clientY - hotCanvas.getBoundingClientRect().top;
    const rect = hotCanvas.getBoundingClientRect();
    const scaleX = hotCanvas.width / rect.width;
    const scaleY = hotCanvas.height / rect.height;
    startX.current = (e.clientX - rect.left) * scaleX;
    startY.current = (e.clientY - rect.top) * scaleY;
  }
  /**
   *
   * Draws the arrow on the canvas
   * @param {*} e
   * @memberof Drawing
   */
  drawArrow(e) {
    const { hotCanvas, startX, startY, ctx_hot } = this.args;
    const rect = hotCanvas.getBoundingClientRect();
    const scaleX = hotCanvas.width / rect.width;
    const scaleY = hotCanvas.height / rect.height;
    const currentX = (e.clientX - rect.left) * scaleX;
    const currentY = (e.clientY - rect.top) * scaleY;
    ctx_hot.clearRect(0, 0, hotCanvas.width, hotCanvas.height);
    ctx_hot.beginPath();
    ctx_hot.moveTo(startX.current, startY.current);
    ctx_hot.lineTo(currentX, currentY);
    this.drawArrowHead(ctx_hot, startX.current, startY.current, currentX, currentY);
    ctx_hot.stroke();
  }
  /**
   *
   * Handles the stopping of the drawing of the arrow
   * @memberof Drawing
   */
  stopDrawingArrow() {
    const { hotCanvas, ctx_hot, ctx_cold } = this.args;
    ctx_cold.drawImage(hotCanvas, 0, 0);
    ctx_hot.clearRect(0, 0, hotCanvas.width, hotCanvas.height);
  }
  /**
   *
   * Clears all the cursor styles
   * @memberof Drawing
   */
  clearCursor() {
    const { hotCanvas, drawMode } = this.args;
    const colors = ["blue", "yellow", "green", "black", "white", "red"];
    switch (drawMode) {
      case "pen":

        for (const color of colors) {
          hotCanvas.classList.remove("pen-cursor-" + color);
        }
        hotCanvas.classList.remove("custom-cursor");
        hotCanvas.style.pointerEvents = 'auto';
        break;
      case "circle":
      case "arrow":
        for (const color of colors) {
          hotCanvas.classList.remove("pen-cursor-" + color);
        }
        hotCanvas.style.pointerEvents = 'auto';
        break;
      default:
        for (const color of colors) {
          hotCanvas.classList.remove("pen-cursor-" + color);
        }
        hotCanvas.style.pointerEvents = 'none';
        break;
    }
  }
  /**
   *
   * Sets the cursor style based on the drawing mode
   * @memberof Drawing
   */
  setCursor() {
    const { hotCanvas, color, drawMode } = this.args;
    if (drawMode === "pen") {
      switch (color) {
        default:
          const cursor = "pen-cursor-" + color;
          hotCanvas.classList.add(cursor);
          break;
        case '#61dafb':
          hotCanvas.classList.add("pen-cursor-blue");
          break;
      }
    } else if (drawMode === "circle" || drawMode === "arrow") {
      hotCanvas.classList.add('custom-cursor');
    }
  }
}

